export default [
    // 管理中心 start
    {
        path: "/V2/ManagementCenter/CustomAdmin",
        name: "V2ManagementCenterCustomAdmin",
        component: () =>
            import("@/views/V2/ManagementCenter/CustomAdmin.vue"),
        meta: {
            title: '客户管理',
            keepAlive: true
        },
    },
    {
        path: "/V2/ManagementCenter/OutsourcingCustomAdmin",
        name: "V2ManagementCenterOutsourcingCustomAdmin",
        component: () =>
            import("@/views/V2/ManagementCenter/OutsourcingCustomAdmin.vue"),
        meta: {
            title: '委外客户管理',
            keepAlive: true
        },
    },

    {
        path: "/V2/ManagementCenter/DeviceList",
        name: "V2ManagementCenterDeviceList",
        component: () =>
            import("@/views/V2/ManagementCenter/DeviceList.vue"),
        meta: {
            title: '仪器管理',
            keepAlive: true
        },
    },
    {
        path: "/V2/ManagementCenter/ConsumablesList",
        name: "V2ManagementCenterConsumablesList",
        component: () =>
            import("@/views/V2/ManagementCenter/ConsumablesList.vue"),
        meta: {
            title: '试剂耗材管理',
            keepAlive: true
        },
    },
    {
        path: "/V2/ManagementCenter/ProjectAdmin",
        name: "V2ManagementCenterProjectAdmin",
        component: () =>
            import("@/views/V2/ManagementCenter/ProjectAdmin.vue"),
        meta: {
            title: '项目管理',
            keepAlive: true
        },
    },

    {
        path: "/V2/ManagementCenter/ProjectAddOrEdit",
        name: "V2ManagementCenterProjectAddOrEdit",
        component: () =>
            import("@/views/V2/ManagementCenter/ProjectAddOrEdit.vue"),
        meta: {
            title: '新增/修改检测项目'
        },
    },
    {
        path: "/V2/ManagementCenter/DataBaseAdmin",
        name: "V2ManagementCenterDataBaseAdmin",
        component: () =>
            import("@/views/V2/ManagementCenter/DataBaseAdmin.vue"),
        meta: {
            title: '数据库管理',
            keepAlive: true
        },
    },

    {
        path: "/V2/ManagementCenter/SystemDictionaryLibrary",
        name: "V2ManagementCenterSystemDictionaryLibrary",
        component: () =>
            import("@/views/V2/ManagementCenter/SystemDictionaryLibrary.vue"),
        meta: {
            title: '系统字典库',
            keepAlive: true
        },
    },

    // 管理中心 end


    // 样本中心 start
    {
        path: "/V2/SampleCenter/SampleList",
        name: "V2SampleCenterSampleList",
        component: () =>
            import("@/views/V2/SampleCenter/SampleList.vue"),
        meta: {
            title: '样本管理',
            keepAlive: true
        },
    },
    {
        path: "/V2/SampleCenter/SampleAddOrEdit",
        name: "V2SampleCenterSampleAddOrEdit",
        component: () =>
            import("@/views/V2/SampleCenter/SampleAddOrEdit.vue"),
        meta: {
            title: '新增/修改样本'
        },
    },
    {
        path: "/V2/SampleCenter/DeliverySample",
        name: "V2SampleCenterDeliverySample",
        component: () =>
            import("@/views/V2/SampleCenter/DeliverySample.vue"),
        meta: {
            title: '外送样本'
        },
    },

    // 样本中心 end
    // 实验中心 start


    {
        path: "/V2/ExperimentalCenter/TestingSamples",
        name: "V2ExperimentalCenterTestingSamples",
        component: () =>
            import("@/views/V2/ExperimentalCenter/TestingSamples.vue"),
        meta: {
            title: '检测样本'
        },
    },
    {
        path: "/V2/ExperimentalCenter/SampleProcessing",
        name: "V2ExperimentalCenterSampleProcessing",
        component: () =>
            import("@/views/V2/ExperimentalCenter/SampleProcessing.vue"),
        meta: {
            title: '样本处理',
            keepAlive: true
        },
    },
    {
        path: "/V2/ExperimentalCenter/SampleProcessingDetails",
        name: "V2ExperimentalCenterSampleProcessingDetails",
        component: () =>
            import("@/views/V2/ExperimentalCenter/SampleProcessingDetails.vue"),
        meta: {
            title: '样本处理详情',
            keepAlive: true
        },
    },

    {
        path: "/V2/ExperimentalCenter/SampleInspection",
        name: "V2ExperimentalCenterSampleInspection",
        component: () =>
            import("@/views/V2/ExperimentalCenter/SampleInspection.vue"),
        meta: {
            title: '样本检验',
            keepAlive: true
        },
    }, {
        path: "/V2/ExperimentalCenter/SampleInspectionDetails",
        name: "V2ExperimentalCenterSampleInspectionDetails",
        component: () =>
            import("@/views/V2/ExperimentalCenter/SampleInspectionDetails.vue"),
        meta: {
            title: '样本检验详情',
            keepAlive: true
        },
    }, {
        path: "/V2/ExperimentalCenter/PGMAssociationTable",
        name: "V2ExperimentalCenterPGMAssociationTable",
        component: () =>
            import("@/views/V2/ExperimentalCenter/PGMAssociationTable.vue"),
        meta: {
            title: 'PGM文库结果关联信息表',
        },
    }, {
        path: "/V2/ExperimentalCenter/PGMOTESAssociationTable",
        name: "V2ExperimentalCenterPGMOTESAssociationTable",
        component: () =>
            import("@/views/V2/ExperimentalCenter/PGMOTESAssociationTable.vue"),
        meta: {
            title: 'PGMOT&ES关联信息表',
        },
    },

    {
        path: "/V2/ExperimentalCenter/CurrencyMonitoringTable",
        name: "V2ExperimentalCenterCurrencyMonitoringTable",
        component: () =>
            import("@/views/V2/ExperimentalCenter/CurrencyMonitoringTable.vue"),
        meta: {
            title: '监控表'
        },
    },
    // 实验中心 end
    {
        path: '/V2/SampleCenter/CashSettlementSampleList',
        name: 'V2SampleCenterCashSettlementSampleList',
        component: () =>
            import("@/views/V2/SampleCenter/CashSettlementSampleList.vue"),
        meta: {
            title: '现结样本列表',
            keepAlive: true
        }
    },
    /**
     * 医学中心 start
     */
    {
        path: '/V2/MedicalCenter/ReportList',
        name: 'V2MedicalCenterReportList',
        component: () =>
            import("@/views/V2/MedicalCenter/ReportList.vue"),
        meta: {
            title: '报告列表',
        }
    },
    {
        path: '/V2/MedicalCenter/ReportPreparation',
        name: 'V2MedicalCenterReportPreparation',
        component: () =>
            import("@/views/V2/MedicalCenter/ReportPreparation.vue"),
        meta: {
            title: '报告编制',
            keepAlive: true
        }
    },
    {
        path: '/V2/MedicalCenter/ReportPreparationDetails',
        name: 'V2MedicalCenterReportPreparationDetails',
        component: () =>
            import("@/views/V2/MedicalCenter/ReportPreparationDetails.vue"),
        meta: {
            title: '报告编制详情',
        }
    },

    {
        path: '/V2/MedicalCenter/ReportToExamineTabPane',
        name: 'V2MedicalCenterReportToExamineTabPane',
        component: () =>
            import("@/views/V2/MedicalCenter/ReportToExamineTabPane.vue"),
        meta: {
            title: '报告审核',
            keepAlive: true
        }
    },


    /**
     * 医学中心 end
     */
    /**
     * 报告管理 start
     */

    {
        path: '/V2/ChannelReportAdmin/ReportUpload',
        name: 'V2ChannelReportAdminReportUpload',
        component: () =>
            import("@/views/V2/ChannelReportAdmin/ReportUpload.vue"),
        meta: {
            title: '报告上传',
            keepAlive: true
        }
    },
    {
        path: '/V2/ChannelReportAdmin/ReportList',
        name: 'V2ChannelReportAdminReportList',
        component: () =>
            import("@/views/V2/ChannelReportAdmin/ReportList.vue"),
        meta: {
            title: '报告列表',
            // keepAlive: true
        }
    },

    /**
     * 报告管理 end
     */
    /**
     * HDL 产品 start
     */
    // {
    //     path: '/V2/HDLProductAdmin/ProductList',
    //     name: 'V2HDLProductAdminProductList',
    //     component: () =>
    //         import("@/views/V2/HDLProductAdmin/ProductList.vue"),
    //     meta: {
    //         title: '产品管理',
    //         // keepAlive: true
    //     }
    // },
    {
        path: '/V2/HDLProductAdmin/SalesPersonList',
        name: 'V2HDLProductAdminSalesPersonList',
        component: () =>
            import("@/views/V2/HDLProductAdmin/SalesPersonList.vue"),
        meta: {
            title: '销售列表',
        }
    },
    {
        path: '/V2/HDLProductAdmin/OrganizationalList',
        name: 'V2HDLProductAdminOrganizationalList',
        component: () =>
            import("@/views/V2/HDLProductAdmin/OrganizationalList.vue"),
        meta: {
            title: '机构列表',
        }
    },
    {
        path: '/V2/HDLProductAdmin/ProductList',
        name: 'V2HDLProductAdminProductList',
        component: () =>
            import("@/views/V2/HDLProductAdmin/ProductList.vue"),
        meta: {
            title: '产品列表',
        }
    },
    {
        path: '/V2/HDLProductAdmin/OrderList',
        name: 'V2HDLProductAdminOrderList',
        component: () =>
            import("@/views/V2/HDLProductAdmin/OrderList.vue"),
        meta: {
            title: '订单列表',
            keepAlive: true,
        }
    },
    {
        path: '/V2/HDLProductAdmin/OrderDetails',
        name: 'V2HDLProductAdminOrderDetails',
        component: () =>
            import("@/views/V2/HDLProductAdmin/OrderDetails.vue"),
        meta: {
            title: '订单详情',
        }
    },




    {
        path: '/V2/HDLProductAdmin/SalesPersonDetails',
        name: 'V2HDLProductAdminSalesPersonDetails',
        component: () =>
            import("@/views/V2/HDLProductAdmin/SalesPersonDetails.vue"),
        meta: {
            title: '销售详情',
            // keepAlive: true
        }
    },
    {
        path: '/V2/HDLProductAdmin/SalesRecords',
        name: 'V2HDLProductAdminSalesRecords',
        component: () =>
            import("@/views/V2/HDLProductAdmin/SalesRecords.vue"),
        meta: {
            title: '购买记录',
            // keepAlive: true
        }
    },

    /**
     * HDL 产品 end
     */
]