export default [

    {
        path: '/test',
        name: 'test',
        component: () =>
            import("@/views/test.vue"),
    },
    {
        path: '/Login',
        name: 'Login',
        component: () =>
            import("@/views/Login/index.vue"),
    },

    /**
     *  系统管理 start
     */
    {
        path: '/SystemAdmin/menu',
        name: 'SystemAdminMenu',
        component: () =>
            import("@/views/SystemAdmin/menu.vue"),
        meta: {
            title: '菜单列表',
            keepAlive: true
        }
    },
    {
        path: '/SystemAdmin/role',
        name: 'SystemAdminRole',
        component: () =>
            import("@/views/SystemAdmin/role.vue"),
        meta: {
            title: '角色列表',
            keepAlive: true
        }
    },
    {
        path: '/SystemAdmin/user',
        name: 'SystemAdminUser',
        component: () =>
            import("@/views/SystemAdmin/user.vue"),
        meta: {
            title: '用户列表',
            keepAlive: true
        }
    },
    {
        path: '/SystemAdmin/api',
        name: 'SystemAdminApi',
        component: () =>
            import("@/views/SystemAdmin/api.vue"),
        meta: {
            title: 'API文档'
        }
    },
    {
        path: '/SystemAdmin/redis',
        name: 'SystemAdminRedis',
        component: () =>
            import("@/views/SystemAdmin/redis.vue"),
        meta: {
            title: 'redis缓存'
        }
    },
    {
        path: '/SystemAdmin/PahyRequest',
        name: 'SystemAdminPahyRequest',
        component: () =>
            import("@/views/SystemAdmin/PahyRequest.vue"),
        meta: {
            title: '平安好医请求'
        }
    },
    
    /**
     *  系统管理 end
     */
    /**
     *  员工管理 start
     */
    {
        path: '/EnterpriseAdmin/department',
        name: 'EnterpriseAdminDepartment',
        component: () =>
            import("@/views/EnterpriseAdmin/department.vue"),
        meta: {
            title: '部门列表',
            keepAlive: true
        }
    },
    {
        path: '/EnterpriseAdmin/post',
        name: 'EnterpriseAdminPost',
        component: () =>
            import("@/views/EnterpriseAdmin/post.vue"),
        meta: {
            title: '岗位列表',
            keepAlive: true
        }
    },

    {
        path: '/EnterpriseAdmin/staff',
        name: 'EnterpriseAdminStaff',
        component: () =>
            import("@/views/EnterpriseAdmin/staff.vue"),
        meta: {
            title: '员工列表',
            keepAlive: true
        }
    },
    /**
     *  员工管理 end
     */
    /**
     * 渠道管理 start
     */

    {
        path: '/ChannelAdmin/CustomerMaintenance',
        name: 'ChannelAdminCustomerMaintenance',
        component: () =>
            import("@/views/ChannelAdmin/CustomerMaintenance.vue"),
        meta: {
            title: '客户列表',
            keepAlive: true
        }
    },
    {
        path: '/ChannelAdmin/SettlementPrice',
        name: 'ChannelAdminSettlementPrice',
        component: () =>
            import("@/views/ChannelAdmin/SettlementPrice.vue"),
        meta: {
            title: '结算价格',
            keepAlive: true
        }
    },
    {
        path: '/ChannelAdmin/SamplePrice',
        name: 'ChannelAdminSamplePrice',
        component: () =>
            import("@/views/ChannelAdmin/SamplePrice.vue"),
        meta: {
            title: '账单核对',
            keepAlive: true
        }
    },
    {
        path: '/ChannelAdmin/InvoiceAddOrEdit',
        name: 'ChannelAdminInvoiceAddOrEdit',
        component: () =>
            import("@/views/ChannelAdmin/InvoiceAddOrEdit.vue"),
        meta: {
            title: '申请开票'
        }
    },
    {
        path: '/ChannelAdmin/InvoiceList',
        name: 'ChannelAdminInvoiceList',
        component: () =>
            import("@/views/ChannelAdmin/InvoiceList.vue"),
        meta: {
            title: '开票列表',
            keepAlive: true
        }
    },
    {
        path: '/ChannelAdmin/InvoiceDetails',
        name: 'ChannelAdminInvoiceDetails',
        component: () =>
            import("@/views/ChannelAdmin/InvoiceDetails.vue"),
        meta: {
            title: '开票详情'
        }
    },

    {
        path: '/ChannelAdmin/OutsourcingCustomerMaintenance',
        name: 'ChannelAdminOutsourcingCustomerMaintenance',
        component: () =>
            import("@/views/ChannelAdmin/OutsourcingCustomerMaintenance.vue"),
        meta: {
            title: '委外客户列表',
            keepAlive: true
        }
    },
    {
        path: '/ChannelAdmin/OutsourcingSamplePrice',
        name: 'ChannelAdminOutsourcingSamplePrice',
        component: () =>
            import("@/views/ChannelAdmin/OutsourcingSamplePrice.vue"),
        meta: {
            title: '委外账单列表',
            keepAlive: true
        }
    },
    {
        path: '/ChannelAdmin/OutsourcingSettleList',
        name: 'ChannelAdminOutsourcingSettleList',
        component: () =>
            import("@/views/ChannelAdmin/OutsourcingSettleList.vue"),
        meta: {
            title: '委外出账列表',
            keepAlive: true
        }
    },
    {
        path: '/ChannelAdmin/OutsourcingSettleDetails',
        name: 'ChannelAdminOutsourcingSettleDetails',
        component: () =>
            import("@/views/ChannelAdmin/OutsourcingSettleDetails.vue"),
        meta: {
            title: '委外出账详情'
        }
    },




    /**
     * 渠道管理 end
     */
    /**
     * 样本中心 start
     */

    {
        path: '/SampleCenter/DeleteSampleList',
        name: 'SampleCenterDeleteSampleList',
        component: () =>
            import("@/views/SampleCenter/DeleteSampleList.vue"),
        meta: {
            title: '删除/修改样本申请',
        }
    },
    {
        path: '/SampleCenter/CashSettlementSampleList',
        name: 'SampleCenterCashSettlementSampleList',
        component: () =>
            import("@/views/SampleCenter/CashSettlementSampleList.vue"),
        meta: {
            title: '现结样本列表',
            keepAlive: true
        }
    },
    {
        path: '/SampleCenter/sampleList',
        name: 'SampleCenterSampleList',
        component: () =>
            import("@/views/SampleCenter/SampleList.vue"),
        meta: {
            title: '样本列表',
            keepAlive: true
        }
    },

    {
        path: '/SampleCenter/UndeterminedSampleList',
        name: 'SampleCenterUndeterminedSampleList',
        component: () =>
            import("@/views/SampleCenter/UndeterminedSampleList.vue"),
        meta: {
            title: '待定样本列表',
            keepAlive: true
        }
    },

    {
        path: '/SampleCenter/sampleAddOrEdit',
        name: 'SampleCenterSampleAddOrEdit',
        component: () =>
            import("@/views/SampleCenter/SampleAddOrEdit.vue"),
        meta: {
            title: '新增/修改样本'
        }
    },
    {
        path: '/SampleCenter/DeliverySample',
        name: 'SampleCenterDeliverySample',
        component: () =>
            import("@/views/SampleCenter/DeliverySample.vue"),
        meta: {
            title: '外送样本'
        }
    },

    {
        path: "/SampleCenter/NucleicAcidExtractionList",
        name: "SampleCenterNucleicAcidExtractionList",
        component: () =>
            import("@/views/SampleCenter/NucleicAcidExtractionList.vue"),
        meta: {
            title: '核酸提取',
            keepAlive: true
        },
    },
    {
        path: '/SampleCenter/SamplePeriodList',
        name: 'SampleCenterSamplePeriodList',
        component: () =>
            import("@/views/SampleCenter/SamplePeriodList.vue"),
        meta: {
            title: '周期管理',
            keepAlive: true
        }
    },
    {
        path: '/SampleCenter/exceptionReminder',
        name: 'SampleCenterExceptionReminder',
        component: () =>
            import("@/views/SampleCenter/ExceptionReminder.vue"),
        meta: {
            title: '异常提醒'
        }
    },
    /**
     * 样本中心 end
     */
    /**
     * 订单管理 start
     */

    {
        path: "/OrderAdmin/order",
        name: "OrderAdminOrder",
        component: () =>
            import("@/views/OrderAdmin/order.vue"),
        meta: {
            title: '订单列表'
        },
    },
    {
        path: "/OrderAdmin/logistics",
        name: "OrderAdminLogistics",
        component: () =>
            import("@/views/OrderAdmin/logistics.vue"),
        meta: {
            title: '物流订单'
        },
    },

    /**
     * 订单管理 end
     */


    /**
     * 基础数据 start
     */

    {
        path: "/BasicDataAdmin/SystemDictionaryLibrary",
        name: "BasicDataAdminSystemDictionaryLibrary",
        component: () =>
            import("@/views/BasicDataAdmin/SystemDictionaryLibrary.vue"),
        meta: {
            title: '系统字典库',
            keepAlive: true
        },
    },

    {
        path: "/BasicDataAdmin/ProjectCategory",
        name: "BasicDataAdminProjectCategory",
        component: () =>
            import("@/views/BasicDataAdmin/ProjectCategory.vue"),
        meta: {
            title: '项目类别',
            keepAlive: true
        },
    }, {
        path: "/BasicDataAdmin/PortfolioProjectList",
        name: "BasicDataAdminPortfolioProjectList",
        component: () =>
            import("@/views/BasicDataAdmin/PortfolioProjectList.vue"),
        meta: {
            title: '组合项目',
            keepAlive: true
        },
    },
    {
        path: "/BasicDataAdmin/PortfolioProjectAddOrEdit",
        name: "BasicDataAdminPortfolioProjectAddOrEdit",
        component: () =>
            import("@/views/BasicDataAdmin/PortfolioProject-add-or-edit.vue"),
        meta: {
            title: '组合项目-新增或修改'
        },
    },
    {
        path: "/BasicDataAdmin/TestingItemList",
        name: "BasicDataAdminTestingItemList",
        component: () =>
            import("@/views/BasicDataAdmin/TestingItem.vue"),
        meta: {
            title: '检验项目',
            keepAlive: true
        },
    },
    {
        path: "/BasicDataAdmin/TestingItemAddOrEdit",
        name: "BasicDataAdminTestingItemAddOrEdit",
        component: () =>
            import("@/views/BasicDataAdmin/TestingItem-add-or-edit.vue"),
        meta: {
            title: '检验项目-新增或修改'
        },
    },
    {
        path: "/BasicDataAdmin/HplcAutoFill",
        name: "BasicDataAdminHplcAutoFill",
        component: () =>
            import("@/views/BasicDataAdmin/HplcAutoFill.vue"),
        meta: {
            title: 'HPLC项目条件'
        },
    },
    {
        path: "/BasicDataAdmin/PGMLibraryPanel",
        name: "BasicDataAdminPGMLibraryPanel",
        component: () =>
            import("@/views/BasicDataAdmin/PGMLibraryPanel.vue"),
        meta: {
            title: 'panel对应项目'
        },
    },
    {
        path: "/BasicDataAdmin/PrimerSiteCorrespondence",
        name: "BasicDataAdminPrimerSiteCorrespondence",
        component: () =>
            import("@/views/BasicDataAdmin/PrimerSiteCorrespondence.vue"),
        meta: {
            title: '一代&液相对照表'
        },
    },
    {
        path: "/BasicDataAdmin/TestingIndex",
        name: "BasicDataAdminTestingIndex",
        component: () =>
            import("@/views/BasicDataAdmin/TestingIndex.vue"),
        meta: {
            title: '检测指标'
        },
    },

    {
        path: "/BasicDataAdmin/DeviceInformationList",
        name: "BasicDataAdminDeviceInformationList",
        component: () =>
            import("@/views/BasicDataAdmin/DeviceInformationList.vue"),
        meta: {
            title: '仪器设备信息',
            keepAlive: true
        },
    },

    {
        path: "/BasicDataAdmin/DeviceInformationAddOrEdit",
        name: "BasicDataAdminDeviceInformationAddOrEdit",
        component: () =>
            import("@/views/BasicDataAdmin/DeviceInformation-add-or-edit.vue"),
        meta: {
            title: '仪器设备信息-新增或修改'
        },
    },
    {
        path: "/BasicDataAdmin/PortfolioProjectList",
        name: "BasicDataAdminPortfolioProjectList",
        component: () =>
            import("@/views/BasicDataAdmin/PortfolioProjectList.vue"),
        meta: {
            title: '组合项目',
            keepAlive: true
        },
    },


    {
        path: "/BasicDataAdmin/NeonatalScreeningProject",
        name: "BasicDataAdminNeonatalScreeningProject",
        component: () =>
            import("@/views/BasicDataAdmin/NeonatalScreeningProject.vue"),
        meta: {
            title: '新生儿筛查项目'
        },
    },
    {
        path: "/BasicDataAdmin/Journal",
        name: "BasicDataAdminJournal",
        component: () =>
            import("@/views/BasicDataAdmin/Journal.vue"),
        meta: {
            title: '操作日志'
        },
    },

    /**
     * 基础数据 end
     */
    /**
     * 实验中心 start
     */


    {
        path: "/ExperimentalCenter/PGMLibraryEstablishment",
        name: "ExperimentalCenterPGMLibraryEstablishment",
        component: () =>
            import("@/views/ExperimentalCenter/PGMLibraryEstablishment.vue"),
        meta: {
            title: 'PGM文库建立',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/PGMHandsOnRecord",
        name: "ExperimentalCenterPGMHandsOnRecord",
        component: () =>
            import("@/views/ExperimentalCenter/PGMHandsOnRecord.vue"),
        meta: {
            title: 'PGM上机记录',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/PGMOTESQualityTesting",
        name: "ExperimentalCenterPGMOTESQualityTesting",
        component: () =>
            import("@/views/ExperimentalCenter/PGMOTESQualityTesting.vue"),
        meta: {
            title: 'PGM OT&ES质检',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/illuminaLibraryEstablishment",
        name: "ExperimentalCenterilluminaLibraryEstablishment",
        component: () =>
            import("@/views/ExperimentalCenter/illuminaLibraryEstablishment.vue"),
        meta: {
            title: 'illumina文库建立',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/illuminaHandsOnRecord",
        name: "ExperimentalCenterilluminaHandsOnRecord",
        component: () =>
            import("@/views/ExperimentalCenter/illuminaHandsOnRecord.vue"),
        meta: {
            title: 'illumina上机记录',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/telomereLength",
        name: "ExperimentalCentertelomereLength",
        component: () =>
            import("@/views/ExperimentalCenter/telomereLength.vue"),
        meta: {
            title: '端粒长度',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/gCMs",
        name: "ExperimentalCentergCMs",
        component: () =>
            import("@/views/ExperimentalCenter/gCMs.vue"),
        meta: {
            title: 'GC-MS',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/liquidChip",
        name: "ExperimentalCenterliquidChip",
        component: () =>
            import("@/views/ExperimentalCenter/liquidChip.vue"),
        meta: {
            title: '液相芯片',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/hdl",
        name: "ExperimentalCenterhdl",
        component: () =>
            import("@/views/ExperimentalCenter/hdl.vue"),
        meta: {
            title: 'HDL',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/hplc",
        name: "ExperimentalCenterhplc",
        component: () =>
            import("@/views/ExperimentalCenter/hplc.vue"),
        meta: {
            title: 'HPLC',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/FragmentAnalysis",
        name: "ExperimentalCenterFragmentAnalysis",
        component: () =>
            import("@/views/ExperimentalCenter/FragmentAnalysis.vue"),
        meta: {
            title: '片段分析法',
            keepAlive: true
        },
    },

    {
        path: "/ExperimentalCenter/QPCRMethod",
        name: "ExperimentalCenterQPCRMethod",
        component: () =>
            import("@/views/ExperimentalCenter/QPCRMethod.vue"),
        meta: {
            title: 'qPCR法',
            keepAlive: true
        },
    },
    {
        path: "/ExperimentalCenter/NeonatalScreening",
        name: "ExperimentalCenterNeonatalScreening",
        component: () =>
            import("@/views/ExperimentalCenter/NeonatalScreening.vue"),
        meta: {
            title: '新生儿筛查',
            keepAlive: true
        },
    },

    /**
     * 实验中心 end
     */


    /**
     * 数据统计 start
     */

    {
        path: "/DataStatistics/Index",
        name: "DataStatisticsIndex",
        component: () =>
            import("@/views/DataStatistics/Index.vue"),
        meta: {
            title: '数据统计',
            // keepAlive: true
        },
    },
    {
        path: "/DataStatistics/SampleSize",
        name: "DataStatisticsSampleSize",
        component: () =>
            import("@/views/DataStatistics/SampleSize.vue"),
        meta: {
            title: '样本量',
            keepAlive: true
        },
    },
    {
        path: "/DataStatistics/SampleSizeMonth",
        name: "DataStatisticsSampleSizeMonth",
        component: () =>
            import("@/views/DataStatistics/SampleSizeMonth.vue"),
        meta: {
            title: '样本明细',
            keepAlive: true
        },
    },
    {
        path: "/DataStatistics/TestItemsSize",
        name: "DataStatisticsTestItemsSize",
        component: () =>
            import("@/views/DataStatistics/TestItemsSize.vue"),
        meta: {
            title: '检测项目量',
            keepAlive: true
        },
    },

    {
        path: "/DataStatistics/DetectionPlatform",
        name: "DataStatisticsDetectionPlatform",
        component: () =>
            import("@/views/DataStatistics/DetectionPlatform.vue"),
        meta: {
            title: '物理组项目数量',
            keepAlive: true
        },
    },
    {
        path: "/DataStatistics/ProjectCategory",
        name: "DataStatisticsProjectCategory",
        component: () =>
            import("@/views/DataStatistics/ProjectCategory.vue"),
        meta: {
            title: '大类项目数量',
            keepAlive: true
        },
    },

    {
        path: "/DataStatistics/TerminalProjectCategory",
        name: "DataStatisticsTerminalProjectCategory",
        component: () =>
            import("@/views/DataStatistics/TerminalProjectCategory.vue"),
        meta: {
            title: '终端大类项目数量',
            keepAlive: true
        },
    },



    {
        path: "/DataStatistics/BaseData",
        name: "DataStatisticsBaseData",
        component: () =>
            import("@/views/DataStatistics/BaseData.vue"),
        meta: {
            title: '基础数据'
        },
    },
    {
        path: "/DataStatistics/EmployeeWorkloadData",
        name: "DataStatisticsEmployeeWorkloadData",
        component: () =>
            import("@/views/DataStatistics/EmployeeWorkloadData.vue"),
        meta: {
            title: '员工工作量'
        },
    },

    {
        path: "/DataStatistics/Workload",
        name: "DataStatisticsWorkload",
        component: () =>
            import("@/views/DataStatistics/Workload.vue"),
        meta: {
            title: '工作量'
        },
    },
    {
        path: "/DataStatistics/OverdueRecord",
        name: "DataStatisticsOverdueRecord",
        component: () =>
            import("@/views/DataStatistics/OverdueRecord.vue"),
        meta: {
            title: '超期记录'
        },
    },

    /**
     * 数据统计 end
     */

    /**
     * 报告管理 end
     */
    {
        path: "/ReportAdmin/ReportPreparation",
        name: "ReportAdminReportPreparation",
        component: () =>
            import("@/views/ReportAdmin/ReportPreparation.vue"),
        meta: {
            title: '报告编制',
        },
    },
    {
        path: "/ReportAdmin/ReportReview",
        name: "ReportAdminReportReview",
        component: () =>
            import("@/views/ReportAdmin/ReportReview.vue"),
        meta: {
            title: '报告审核'
        },
    },
    {
        path: "/ReportAdmin/ReportUpload",
        name: "ReportAdminReportUpload",
        component: () =>
            import("@/views/ReportAdmin/ReportUpload.vue"),
        meta: {
            title: '报告上传'
        },
    },
    {
        path: "/ReportAdmin/ReportDistribution",
        name: "ReportAdminReportDistribution",
        component: () =>
            import("@/views/ReportAdmin/ReportDistribution.vue"),
        meta: {
            title: '报告发放'
        },
    },

    {
        path: "/ReportAdmin/ReportTemplate",
        name: "ReportAdminReportTemplate",
        component: () =>
            import("@/views/ReportAdmin/ReportTemplate.vue"),
        meta: {
            title: '模板管理'
        },
    },
    {
        path: "/ReportAdmin/ComprehensiveInformationQuery",
        name: "ReportAdminComprehensiveInformationQuery",
        component: () =>
            import("@/views/ReportAdmin/ComprehensiveInformationQuery.vue"),
        meta: {
            title: '信息综合查询'
        },
    },


    /**
     * 报告管理 end
     */

]