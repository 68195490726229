var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"},on:{"click":_vm.appClick}},[_c('div',{staticStyle:{"opacity":"0","position":"absolute","bottom":"0","left":"0","transform":"translate(-100%, -100%)"}},[_c('el-image',{staticStyle:{"max-width":"100%","max-height":"100%"},attrs:{"id":"imagePreview","src":this.$store.state.pigImgUrl,"preview-src-list":this.$store.state.pigImgUrlList}})],1),(
      _vm.$route.name !== 'Login' && _vm.$route.name !== 'StaffLogin' && _vm.$route.name
    )?_c('div',{staticClass:"appMain"},[(!_vm.$store.state.version)?_c('CurrencySidebarVue'):_vm._e(),(!_vm.$store.state.version)?_c('div',{staticClass:"appRight"},[_c('CurrencyHeader'),_c('CurrencyTagNavigation'),_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view',{key:_vm.$route.fullPath,staticClass:"routerView",style:({
            width: `calc(100vw - ${
              _vm.$store.state.currencySidebarFlag ? '200px' : '0px'
            }) !important`,
          })}):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view',{key:_vm.$route.fullPath,staticClass:"routerView",style:({
          width: `calc(100vw - ${
            _vm.$store.state.currencySidebarFlag ? '200px' : '0px'
          }) !important`,
        })}):_vm._e()],1):_vm._e(),(_vm.$store.state.version)?_c('div',{staticClass:"version"},[_c('versionHeader'),_c('currencySecondaryMenu'),_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view',{key:_vm.$route.fullPath,staticClass:"routerView"}):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view',{key:_vm.$route.fullPath,staticClass:"routerView"}):_vm._e()],1):_vm._e()],1):_c('router-view'),_c('el-dialog',{attrs:{"title":"在线预览","visible":_vm.$store.state.onlinePreviewVisible,"width":"80%","center":""},on:{"update:visible":function($event){return _vm.$set(_vm.$store.state, "onlinePreviewVisible", $event)}}},[(_vm.$store.state.onlinePreviewUrlArr.length == 1)?_c('iframe',{ref:"pdf",staticClass:"onlinePreviewIframe",attrs:{"src":_vm.$store.state.onlinePreviewUrl}}):_vm._l((_vm.$store.state.onlinePreviewUrlArr),function(item,index){return _c('iframe',{key:index,ref:"pdf",refInFor:true,staticClass:"onlinePreviewIframe",attrs:{"src":item}})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }