<template>
  <el-dialog title="修改密码" :visible.sync="visible" width="840px" center>
    <el-form
      :model="dataForm"
      :rules="dataFormRules"
      ref="dataForm"
      label-position="right"
      label-width="110px"
      @keyup.enter.native="updatePassword('dataForm')"
    >
      <div class="formPs" style="margin-bottom: 10px">
        密码必须是包含大写字母、小写字母、数字、特殊符号（不是字母，数字，下划线，汉字的字符）的8位以上组合，不能包含#
      </div>
      <el-form-item label="当前密码：" prop="password">
        <el-input v-model.trim="dataForm.password" show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newPassword">
        <el-input v-model.trim="dataForm.newPassword" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认新密码：" prop="repeatNewPassword">
        <el-input v-model.trim="dataForm.repeatNewPassword" show-password></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消修改</el-button>
      <el-button type="primary" @click="updatePassword('dataForm')" :loading="loadingFlag"
        >确认修改</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {},
      dataFormRules: {
        password: [{ required: true, trigger: "blur", message: "当前密码不能为空！" }],
        newPassword: [
          { required: true, trigger: "blur", message: "新密码不能为空！" },
          {
            pattern: /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8,}$/,
            message:
              "密码必须是包含大写字母、小写字母、数字、特殊符号（不是字母，数字，下划线，汉字的字符）的8位以上组合",
          },
        ],
        repeatNewPassword: [
          { required: true, trigger: "blur", message: "确认新密码不能为空！" },
          {
            pattern: /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8,}$/,
            message:
              "密码必须是包含大写字母、小写字母、数字、特殊符号（不是字母，数字，下划线，汉字的字符）的8位以上组合",
          },
        ],
      },
      loadingFlag: false,
    };
  },
  mounted() {},
  methods: {
    init() {
      this.visible = true;
      this.dataForm = {
        userId: this.getUserId(),
      };
    },
    //修改密码
    updatePassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.dataForm;
          if (data.repeatNewPassword != data.newPassword) {
            this.$VueMessage({
              message: "两次密码不一致，请确认",
              type: "error",
            });
            return;
          }
          let url = "/lims-admin/sys-user/password";
          let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
          if (loginInfo.id) url = "lims-admin/employee/password";
          this.loadingFlag = true;
          this.$http
            .post(url, data)
            .then((res) => {
              this.loadingFlag = false;
              if (res.data.code == 200) {
                this.$VueMessage({
                  message: "修改密码成功，请重新登录",
                  type: "success",
                });
                this.visible = false;
                this.$logout();
              }
            })
            .catch(() => {
              this.loadingFlag = false;
            });
        }
      });
    },
  },
};
</script>
