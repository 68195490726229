export default {
  install(Vue) {
    Vue.prototype.isAuth = function (key) {
      return JSON.parse(localStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
    }
    /**
     * 请求参数过滤，查询全部的时候修改为空字符串
     */
    Vue.prototype.getParameterProcessing = function (data) {
      data = JSON.parse(JSON.stringify(data))
      for (let key in data) {
        if (data[key] == '全部') data[key] = ''
      }
      return data
    }
    /**
     * post page请求参数处理
     */
    Vue.prototype.postParameterProcessing = function (data) {
      let allConditions = ['allEq', 'eq', 'ge', 'in', 'isNotNull', 'isNull', 'le', 'like', 'ne', 'orderBy', 'select', 'set']
      let object = {}
      for (const key in data) {
        if (allConditions.includes(key)) {
          object[key] = []
          data[key].forEach(item => {
            // if (item[2]) {
            object[key].push({
              "fieldDataType": item[0],
              "fieldName": item[1],
              "fieldValue": item[2]
            })
            // }
          })
        } else object[key] = data[key]
      }
      return object
    }
    /**
     * minio根据图片名称删除
     */
    Vue.prototype.$deleteByNameFile = function (name) {
      this.$http.delete(`/lims-admin/minio/deleteByName?name=${name}`)
    }
    /**
     * 统一的上传PDF前的处理 
     */
    Vue.prototype.$beforeUploadPdf = function (file) {
      if (file.type && file.type.indexOf("pdf") != -1) {
        this.$store.state.beforeUploadLoading = this.$loading({
          lock: true,
          text: "正在上传，请稍等......",
          spinner: "el-icon-loading",
        })
      } else {
        this.$VueMessage({
          message: "请上传PDF格式的文件",
          type: "warning",
        })
        return false
      }
    }
    /**
     *  统一的上传图片前的处理 
     */
    Vue.prototype.$beforeUploadImage = function (file) {
      if (file.type && file.type.indexOf("image") != -1) {
        this.$store.state.beforeUploadLoading = this.$loading({
          lock: true,
          text: "正在上传，请稍等......",
          spinner: "el-icon-loading",
        })
      } else {
        this.$VueMessage({
          message: "请上传图片格式的文件",
          type: "warning",
        })
        return false
      }
    }
    /**
     *  统一的上传PDF前的处理 
     */
    Vue.prototype.$beforeUploadPdf = function (file) {
      if (file.type && file.type.indexOf("pdf") != -1) {
        this.$store.state.beforeUploadLoading = this.$loading({
          lock: true,
          text: "正在上传，请稍等......",
          spinner: "el-icon-loading",
        })
      } else {
        this.$VueMessage({
          message: "请上传PDF格式的文件",
          type: "warning",
        })
        return false
      }
    }
    /**
     *  统一的上传Excel前的处理 
     */
    Vue.prototype.$beforeUploadExcel = function (file) {
      if (file.type && file.type.indexOf("openxmlformats") != -1) {
        this.$store.state.beforeUploadLoading = this.$loading({
          lock: true,
          text: "正在上传，请稍等......",
          spinner: "el-icon-loading",
        })
      } else {
        this.$VueMessage({
          message: "请上传Excel格式的文件",
          type: "warning",
        })
        return false
      }
    }
    /**
     *  统一的上传错误处理
     */
    Vue.prototype.$uploadError = function () {
      this.$store.state.beforeUploadLoading.close()
      this.$VueMessage({
        message: "上传失败，请重试或联系管理员",
        type: "warning",
      })
    }

    /**
     *  统一处理全局查看图片 
     */
    Vue.prototype.$VueSeePigImg = function (url, urlList) {
      this.$store.state.pigImgUrl = url
      this.$store.state.pigImgUrlList = urlList || [url]
      let seePigImgLoading = this.$loading({
        lock: true,
        text: "加载中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
      let setId = setInterval(() => {
        if (document.getElementById("imagePreview")) {
          seePigImgLoading.close()
          clearInterval(setId)
          document.getElementById("imagePreview").click()
        }
      }, 200)
    }
    /**
     *  统一处理全局提示，避免出现太多提示框，限制最多三个
     */
    Vue.prototype.$VueMessage = function (data) {
      if (document.getElementsByClassName("el-message").length < 5) {
        if (!data.message) data.message = '操作失败，请联系管理员'
        this.$message(data)
      }
    }
    /**
     * 过滤日期时间格式
     * @param {Number} time 时间戳
     * @param {String} format 日期格式限制
     * @returns 返回格式化后的日期时间格式
     */
    Vue.prototype.dateTimeFormat = function (time, format) {
      if (time) {
        let timeStr = ''
        /* 有些时间戳是10位数，处理成13位数 */
        if (String(time).length == 10) {
          time = time + '000'
          time = Number(time)
        }
        if (String(time).indexOf('-') != -1) {
          time = String(time).replace(/-/g, '/')
        }
        let value = new Date(time)
        let year = value.getFullYear()
        let month = value.getMonth() + 1
        if (month < 10)
          month = "0" + month
        let day = value.getDate()
        if (day < 10)
          day = "0" + day
        let hour = value.getHours()
        if (hour < 10)
          hour = "0" + hour
        let minutes = value.getMinutes()
        if (minutes < 10)
          minutes = "0" + minutes
        let seconds = value.getSeconds()
        if (seconds < 10)
          seconds = "0" + seconds
        if (format == 'NYR')
          timeStr = `${year}-${month}-${day}`
        else if (format == 'NYRR')
          timeStr = `${year}${month}${day}`
        else if (format == 'NYRSF')
          timeStr = `${year}-${month}-${day} ${hour}:${minutes}`
        else timeStr = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
        return timeStr
      } else return ''
    }
    /**
     * 根据用户信息查询菜单
     */
    Vue.prototype.getMenu = function (isJump) {
      let menuLoading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
      let loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
      let url = `/lims-admin/sys-menu/nav?userId=${loginInfo.userId}`
      if (loginInfo.roleId)
        //不存在用户id，用角色id查询权限
        url = `/lims-admin/sys-menu/navRoleId?roleId=${loginInfo.roleId}`
      this.$http
        .get(url)
        .then((res) => {
          if (res && res.data.code == 200) {
            menuLoading.close()
            let data = res.data.data
            if (data && data.length > 0) {
              this.$store.state.menuList = data
              if (this.$route.name == 'Login' || this.$route.name == 'StaffLogin') {
                this.$store.state.activeMenuId = data[0].menuId
                this.$store.state.secondLevelMenuList = data[0].list
                this.$router.replace({ name: data[0].list[0].url })
              } else {
                let index = localStorage.getItem('activeMenuIndex') || 0
                this.$store.state.activeMenuId = data[index].menuId
                this.$store.state.secondLevelMenuList = data[index].list
                setTimeout(() => {
                  if (!this.$route.name)
                    this.$router.replace({ name: data[index].list[0].url })
                }, 200)
              }
              let list = []
              this.getRoleMenuList(data, list)
              localStorage.setItem("permissions", JSON.stringify(list))

              // 每次获取菜单的时候，判断当前选中的菜单进行渲染
              // let index = localStorage.getItem('activeMenuIndex');
              // let menuId = localStorage.getItem('activeMenuId');
              // let isLogin = (!this.$route.name || this.$route.name != 'Login' || this.$route.name != 'StaffLogin')
              // if (this.$route.name != 'Login' && this.$route.name != 'StaffLogin') {
              //     this.$store.state.activeMenuId = data[index].menuId;
              //     this.$store.state.secondLevelMenuList = data[index].list;
              //     setTimeout(() => {
              //         if (!this.$route.name)
              //             this.$router.replace({ name: data[index].list[0].url });
              //     }, 200)

              // } else if (index && menuId == data[index].menuId) {
              //     this.$store.state.activeMenuId = data[index].menuId;
              //     this.$store.state.secondLevelMenuList = data[index].list;
              //     if (isLogin && !this.$route.name)
              //         this.$router.replace({ name: data[index].list[0].url });
              // } else if (!this.$route.name || this.$route.name == 'Login' || this.$route.name == 'StaffLogin') {
              //     this.$store.state.activeMenuId = data[0].menuId;
              //     this.$store.state.secondLevelMenuList = data[0].list;
              //     localStorage.setItem('activeMenuIndex', 0);
              //     localStorage.setItem('activeMenuId', data[0].menuId);
              //     try {
              //         this.$router.replace({ name: data[0].list[0].url });
              //     } catch (err) {
              //         this.$store.state.activeMenuId = data[data.length - 1].menuId
              //         this.$store.state.secondLevelMenuList = data[data.length - 1].list
              //         localStorage.setItem('activeMenuIndex', data.length - 1);
              //         localStorage.setItem('activeMenuId', data[data.length - 1].menuId);
              //         if (this.$route.name != this.$store.state.secondLevelMenuList[0].url)
              //             this.$router.replace({ name: this.$store.state.secondLevelMenuList[0].url });
              //         else
              //             this.$router.replace({ name: 'SystemAdminMenu' });
              //     }
              // }
              // setTimeout(() => {
              //     try {
              //         document
              //             .getElementById(this.$store.state.activeMenuId)
              //             .scrollIntoView({ block: "center", behavior: "smooth", inline: "center" });
              //     } catch (err) {
              //     }
              // }, 200);
            } else {
              this.$VueMessage({
                message: "该账户暂无权限登录，请联系管理员",
                type: "error",
              })
              localStorage.removeItem('loginInfo')
              if (this.$route.name != 'Login') this.$router.replace({ name: 'Login' })
            }
          } else {
            menuLoading.close()
            this.$logout()
          }

        })
    }
    /**
     * 递归 获取授权菜单
     */
    Vue.prototype.getRoleMenuList = function (data, list) {
      data.forEach((item) => {
        if (item.list.length > 0) {
          this.getRoleMenuList(item.list, list)
        } else if (item.perms) list.push(item.perms)
      })
    }
    /**
     * 获取登录用户角色名称
     */
    Vue.prototype.getUserRoleName = function () {
      if (localStorage.getItem('loginInfo')) {
        let loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
        let roleName = ''
        if (
          loginInfo.sysRoleList &&
          loginInfo.sysRoleList.length > 0 &&
          loginInfo.sysRoleList[0].roleName) {
          roleName = loginInfo.sysRoleList[0].roleName
        } else roleName = loginInfo.roleName
        return roleName
      }
    }
    /**
     * 获取登录用户Id
     */
    Vue.prototype.getUserId = function () {
      let loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
      let userId = ''
      if (loginInfo.id) {
        userId = loginInfo.id
      } else userId = loginInfo.userId
      return userId
    }
    /**
     * 获取登录用户名称
     */
    Vue.prototype.getUserName = function () {
      let loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
      let userName = ''
      userName = loginInfo.name
      return userName
    }
    /**
     * 全局退出登录的接口
     */
    Vue.prototype.$logout = function () {
      if (localStorage.getItem('loginInfo')) {
        localStorage.clear()
        location.reload()
        this.$http.post(`lims-admin/sys-user/loginOut`).then(res => {
          this.$router.replace({
            name: localStorage.getItem('loginUrl') || 'Login'
          })
          localStorage.clear()
        })
      }
    }
    /**
     * 根据参数获取系统字典的列表
     */
    Vue.prototype.getBaseDictionaryAllList = function (dataCode, listName, parentId) {
      return new Promise((success) => {
        let postData = {
          eq: [["String", "data_code", dataCode]],
          orderBy: [["String", "sort", "true"]],
          ne: [["String", "parent_id", "0"]],
        }
        if (parentId) postData.eq.push(["String", "parent_id", parentId])
        this.$http
          .post(
            `lims-admin/base-dictionary/getListCondition`,
            this.postParameterProcessing(postData)
          )
          .then((res) => {
            if (res.data.code == 200) {
              let data = res.data.data
              if (data) this.$store.state[listName] = data
            }
          })
      })
    }
    /**
     * 操作日志的统一数据处理
     */
    Vue.prototype.operationLogDataHandle = function (list) {
      list.forEach((item) => {
        if (item.eventName == "修改") {
          item.afterContent = JSON.parse(item.afterContent)
          for (let key in item.afterContent) {
            let v = item.afterContent[key]
            if (v) {
              item.afterContent[key] = v
            }
          }
          item.beforeContent = JSON.parse(item.beforeContent)
          for (let key in item.beforeContent) {
            let v = item.beforeContent[key]
            if (v) {
              item.beforeContent[key] = v
            }
          }
        } else if (item.eventName == "删除" || item.eventName == "新增") {
          item.actionContent = JSON.parse(item.actionContent)
          for (let key in item.actionContent) {
            let v = item.actionContent[key]
            if (v) {
              if (key == "修改时间" || key == "创建时间") {
                v = this.dateTimeFormat(v)
              }
              item.actionContent[key] = v
            }
          }
        }
      })
      return list
    }
    /**
     * 根据条件修改周期状态
     * type 默认1  PGM type=2  illumina type=3
     */
    Vue.prototype.samplePeriodUpdateStatus = function (data) {
      // this.$http.post(`lims-report/sample-period/updateStatus?barCode=${data.barCode}&itemsCode=${data.itemsCode}&sampleType=${data.sampleType}&type=${data.type || 1}`)
    }
    /**
     * 根据参数暂停/取消检测
     */
    Vue.prototype.suspendOrCancelCheck = function (id, status, isDetail, callback) {
      let url = `lims-report/sample/suspend`
      if (isDetail)
        url = `lims-report/sample-detail/suspend`
      let loading = this.$loading({
        lock: true,
        text: "操作中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
      this.$http.post(`${url}/${id}?status=${status}`).then(res => {
        loading.close()
        callback()
        if (res.code == 200) {
          this.$VueMessage({
            message: "操作成功",
            type: "success",
          })
        }
      })
    }
    /**
     * 在线预览文件 iframe
     */
    Vue.prototype.onlinePreview = function (url) {
      this.$store.state.onlinePreviewVisible = true
      this.$store.state.onlinePreviewUrl = url
      this.$store.state.onlinePreviewUrlArr = url.split(',')
      console.log(url.split(','))
    }

    Vue.prototype.tableSortChange = function (e) {
      let exportSortKey = ''
      let exportSortValue = ''
      if (e.prop) {
        if (e.prop == 'barCode')
          exportSortKey = 'bar_code'
        else if (e.prop == "itemsName")
          exportSortKey = "items_name"
        else if (e.prop == 'sampleNo') exportSortKey = "sample_no"
      }
      if (e.order) {
        if (e.order == 'ascending')
          exportSortValue = false
        else
          exportSortValue = true
      } else
        exportSortValue = ''
      localStorage.setItem("exportSortKey", exportSortKey)
      localStorage.setItem("exportSortValue", exportSortValue)
    }
    Vue.prototype.exportSort = function (data) {
      if (localStorage.getItem("exportSortValue")) {
        data.orderBy = localStorage.getItem("exportSortKey")
        data.desc = localStorage.getItem("exportSortValue")
      }
      return data
    }
    Vue.prototype.goCurrencyMonitoringTable = function (row, type) {
      localStorage.setItem('CurrencyMonitoringTable', JSON.stringify(row))
      this.$router.push({
        name: "V2ExperimentalCenterCurrencyMonitoringTable",
        query: {
          type
        },
      })
    }
    /**
     * 通用的处理参考范围，标上下箭头
     */
    Vue.prototype.handleReferenceRange = function (data) {
      if (data.referenceRange && data.result) {

        let result = data.result
        if (result.indexOf('%') != -1)
          result = result.replace('%', '')

        if (/^\d+$/.test(result) || /^(-?[1-9]\d*\.\d+|-?0\.\d*[1-9]\d*|0\.0+)$/.test(result)) {
          result = Number(result)
          let arr = []
          if (data.testingIndex == '胆固醇平衡系数') {
            if (result > 0 && result <= 0.5)
              data.prompt = '吸收过量'
            else if (result > 0.5 && result <= 1.1)
              data.prompt = '平衡(正常)'
            else if (result > 1.1)
              data.prompt = '合成过量'
            return data
          }
          let range = data.referenceRange
          if (range.indexOf('%') != -1)
            range = range.replace('%', '')
          if (range.indexOf('~') != -1) {
            arr = range.split('~')
            if (result < arr[0])
              data.prompt = '↓'
            else if (result > arr[1])
              data.prompt = '↑'
            else data.prompt = ''
          }
          else if (range.indexOf('<') != -1) {
            arr = range.split('<')
            if (result > arr[1])
              data.prompt = '↑'
            else data.prompt = ''

          } else if (range.indexOf('>') != -1) {
            arr = range.split('>')
            if (result < arr[1])
              data.prompt = '↓'
            else data.prompt = ''

          } else if (range.indexOf('≥') != -1) {
            arr = range.split('≥')
            if (result < arr[1])
              data.prompt = '↓'
            else data.prompt = ''

          } else if (range.indexOf('≤') != -1) {
            arr = range.split('≤')
            if (result < arr[1])
              data.prompt = '↓'
            else data.prompt = ''
          }
        }
      } else data.prompt = ''
      return data
    }

    Vue.prototype.handleReferenceRangeZeroFill = function (data) {
      if (data.referenceRange && data.result) {
        let result = data.result
        let isUnit = false
        if (result.indexOf('%') != -1) {
          result = result.replace('%', '')
          isUnit = true
        }

        if (/^\d+$/.test(result) || /^(-?[1-9]\d*\.\d+|-?0\.\d*[1-9]\d*|0\.0+)$/.test(result)) {
          let decimalPlaces = 0
          result = Number(result)
          let arr = []
          let range = ''
          if (data.referenceRange.indexOf('%') != -1) {
            data.referenceRange.replace('%', '')
          }
          range = data.referenceRange
          if (range.indexOf('~') != -1)
            arr = range.split('~')
          else if (range.indexOf('<') != -1)
            arr = range.split('<')
          else if (range.indexOf('>') != -1)
            arr = range.split('>')
          else if (range.indexOf('≥') != -1)
            arr = range.split('≥')
          else if (range.indexOf('≤') != -1)
            arr = range.split('≤')
          if (arr[0].indexOf('.') != -1)
            decimalPlaces = arr[0].split('.')[1].length
          if (arr[1].indexOf('.') != -1) {
            if (decimalPlaces && arr[1].split('.')[1].length > decimalPlaces)
              decimalPlaces = arr[1].split('.')[1].length
            else
              decimalPlaces = arr[1].split('.')[1].length
          }
          // 血液浓度固定小数点
          if (data.itemCode.indexOf('C-XYND') != -1) decimalPlaces = 2
          if (data.itemCode.indexOf('G-YCB') != -1) decimalPlaces = 0
          if (data.testingIndex == '胆固醇平衡系数') decimalPlaces = 2
          // 如果结果有小数位，并且比上面定义的小数位大就用结果的小数位
          if (String(result).indexOf('.') != -1)
            if (decimalPlaces && String(result).split('.')[1].length > decimalPlaces)
              decimalPlaces = String(result).split('.')[1].length
          if (result)
            result = result.toFixed(decimalPlaces)
          if (isUnit) result += '%'
          data.result = result
        }
      }
      return data
    }

  }
}