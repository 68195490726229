<template>
  <div class="currencySecondaryMenu">
    <div
      :class="{
        item: true,
        active: $route.name && $route.name.indexOf(item.url) != -1,
      }"
      v-for="(item, index) in $store.state.secondLevelMenuList"
      :key="index"
      @click="goPage(item.url)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goPage(url) {
      if (url != this.$route.name) this.$router.replace({ name: url });
    },
  },
};
</script>
<style lang="less" scoped>
.currencySecondaryMenu {
  background-color: #fff;
  font-size: 16px;
  line-height: 50px;
  height: 50px;
  overflow-x: auto;
  display: flex;
  align-items: center;

  .item {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    color: #333;
    font-size: 14px;
  }
  .item.active,
  .item:hover {
    background-color: @mainBackgroundColor;
    color: #fff;
  }
}
</style>
