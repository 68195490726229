import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: true,
    currencySidebarFlag: true,
    uploadHeaders: {},
    menuList: [],//角色权限目录/菜单/按钮
    activeMenuId: '',//当前选中目录id
    secondLevelMenuList: [],//当前选中目录的菜单

    beforeUploadLoading: null,//上传文件前的loading动画

    pigImgUrlList: [],//全局查看图片的list
    pigImgUrl: '',//全局查看图片的单张
    uploadImgUrl: '',//上传图片接口地址
    baseUrl: '',
    // 通用的日期时间快捷方式选择
    pickerOptions: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    },
    eventNameAllList: [
      { name: "新增" },
      { name: "修改" },
      { name: "删除" },
    ],
    // 基础数据下的系统字典库 列表
    detectionMethodAllList: [],
    professionalGroupAllList: [],
    physicsGroupAllList: [],
    pathologyAllList: [],
    sampleTypeAllList: [],
    tubeTypeAllList: [],
    resultTypeAllList: [],
    sampleStatusAllList: [],
    // 核酸提取类型
    extractionTypeAllList: [],
    detectionStatus: [
      {
        label: '待检测',
        value: 1
      }, {
        label: '检测中',
        value: 2,
        type: 'warning'
      }, {
        label: '检测完成',
        value: 3,
        type: 'success'
      }, {
        label: '暂停检测',
        value: 4,
        type: 'danger'
      }, {
        label: '取消检测',
        value: 5,
        type: 'danger'
      }, {
        label: '已出报告',
        value: 6,
        type: 'danger'
      },
    ],
    toExamineStatus: [
      {
        label: '待审核',
        value: 1
      }, {
        label: '审核通过',
        value: 2,
        type: 'success'
      },
      // {
      //   label: '审核不通过',
      //   value: 3,
      //   type: "danger"
      // },
    ],
    onlinePreviewVisible: false,
    onlinePreviewUrl: '',
    onlinePreviewUrlArr: [],
    exportSortKey: "",
    exportSortValue: '',
    // 实验中心的下拉框选择项目
    ExperimentalCenterItems: [],
    hide: false
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
