<template>
  <div id="app" @click="appClick">
    <div
      style="
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-100%, -100%);
      "
    >
      <el-image
        id="imagePreview"
        style="max-width: 100%; max-height: 100%"
        :src="this.$store.state.pigImgUrl"
        :preview-src-list="this.$store.state.pigImgUrlList"
      >
      </el-image>
    </div>
    <div
      v-if="
        $route.name !== 'Login' && $route.name !== 'StaffLogin' && $route.name
      "
      class="appMain"
    >
      <CurrencySidebarVue v-if="!$store.state.version" />
      <div class="appRight" v-if="!$store.state.version">
        <CurrencyHeader />
        <CurrencyTagNavigation />
        <keep-alive>
          <router-view
            v-if="$route.meta.keepAlive"
            class="routerView"
            :style="{
              width: `calc(100vw - ${
                $store.state.currencySidebarFlag ? '200px' : '0px'
              }) !important`,
            }"
            :key="$route.fullPath"
          />
        </keep-alive>
        <router-view
          v-if="!$route.meta.keepAlive"
          class="routerView"
          :style="{
            width: `calc(100vw - ${
              $store.state.currencySidebarFlag ? '200px' : '0px'
            }) !important`,
          }"
          :key="$route.fullPath"
        />
      </div>
      <div class="version" v-if="$store.state.version">
        <versionHeader></versionHeader>
        <!-- <CurrencyTagNavigation /> -->
        <!-- 二级菜单 -->
        <currencySecondaryMenu></currencySecondaryMenu>
        <keep-alive>
          <router-view
            v-if="$route.meta.keepAlive"
            class="routerView"
            :key="$route.fullPath"
          />
        </keep-alive>
        <router-view
          v-if="!$route.meta.keepAlive"
          class="routerView"
          :key="$route.fullPath"
        />
      </div>
    </div>
    <router-view v-else />

    <el-dialog
      title="在线预览"
      :visible.sync="$store.state.onlinePreviewVisible"
      width="80%"
      center
    >
      <iframe
        ref="pdf"
        :src="$store.state.onlinePreviewUrl"
        v-if="$store.state.onlinePreviewUrlArr.length == 1"
        class="onlinePreviewIframe"
      ></iframe>
      <iframe
        ref="pdf"
        :src="item"
        v-else
        v-for="(item, index) in $store.state.onlinePreviewUrlArr"
        :key="index"
        class="onlinePreviewIframe"
      ></iframe>
    </el-dialog>
  </div>
</template>
<script>
import CurrencyHeader from "@/components/currencyHeader.vue";
import CurrencySidebarVue from "@/components/currencySidebar.vue";
import CurrencyTagNavigation from "@/components/currencyTagNavigation.vue";
import versionHeader from "@/components/versionHeader.vue";
import currencySecondaryMenu from "@/components/currencySecondaryMenu.vue";

export default {
  components: {
    CurrencyHeader,
    CurrencySidebarVue,
    CurrencyTagNavigation,
    versionHeader,
    currencySecondaryMenu,
  },
  data() {
    return {
      clickTime: new Date().getTime(),
      signOutTime: 60 * 60 * 2000,
      setIntervalId: null,
    };
  },
  mounted() {
    if (localStorage.getItem("currencySidebarFlag"))
      this.$store.state.currencySidebarFlag =
        localStorage.getItem("currencySidebarFlag") == "false" ? false : true;
    if (localStorage.getItem("hide")) {
      this.$store.state.hide = true;
    }
    /**
     * 1、判断没有登录信息，并且不是在登录页的话跳转到登录页
     * 2、判断有登录信息去获取菜单
     */
    if (
      !localStorage.getItem("loginInfo") &&
      this.$route.name != "Login" &&
      this.$route.name != "StaffLogin"
    ) {
      this.$router.replace({
        name: localStorage.getItem("loginUrl") || `Login`,
      });
    } else if (localStorage.getItem("loginInfo")) {
      this.isExistOrDisable();
      this.$store.state.uploadHeaders = {
        "access-token": localStorage.getItem("access-token"),
      };
      this.getMenu(false);
    }
    if (location.port) {
      this.$store.state.baseUrl = "http://192.168.1.41:6062";
      this.$store.state.uploadImgUrl =
        "http://192.168.1.41:6062/lims-admin/minio/uploadMinIoFile";
      this.$store.state.baseUrl = "http://s8434004c4.yicp.fun:80";
      this.$store.state.uploadImgUrl =
        "http://s8434004c4.yicp.fun:80/lims-admin/minio/uploadMinIoFile";
      this.$store.state.baseUrl = "http://s8434004c4.yicp.fun:80";
      this.$store.state.uploadImgUrl =
        "http://s8434004c4.yicp.fun:80/lims-admin/minio/uploadMinIoFile";
      // this.$store.state.baseUrl = "https://apilims.ardentbiomed.com.cn";
      // this.$store.state.uploadImgUrl =
      //   "https://apilims.ardentbiomed.com.cn/lims-admin/minio/uploadMinIoFile";
    } else {
      this.$store.state.baseUrl = "https://apilims.ardentbiomed.com.cn";
      this.$store.state.uploadImgUrl =
        "https://apilims.ardentbiomed.com.cn/lims-admin/minio/uploadMinIoFile";
    }

    this.setIntervalId = setInterval(() => {
      this.timeoutLogout();
    }, 1000 * 60);
    localStorage.removeItem("exportSortKey");
    localStorage.removeItem("exportSortValue");
  },
  methods: {
    /**
     * 判断当前登录账户是否存在或禁用，如果用户不存在直接退出登录，如果用户存在但是是禁用状态也退出登录
     */
    async isExistOrDisable() {
      let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
      let res = {};
      if (loginInfo.userId)
        res = await this.$http.get(
          `/lims-admin/sys-user/baseGetById/${loginInfo.userId}`
        );
      else
        res = await this.$http.get(
          `/lims-admin/employee/baseGetById/${loginInfo.id}`
        );

      if (res.data.code == 200) {
        let data = res.data.data;
        if (data) {
          if (data.status != 1) {
            this.$logout();
          }
        } else this.$logout();
      }
    },
    /**
     * 定时器到时间后退出登录
     */
    timeoutLogout() {
      if (localStorage.getItem("loginInfo")) {
        let currentTime = new Date().getTime();
        let tempTiem = currentTime - this.clickTime;
        if (tempTiem > this.signOutTime) {
          clearInterval(this.setIntervalId);
          this.$logout();
        }
      }
    },
    appClick() {
      this.clickTime = new Date().getTime();
    },
  },

  destroyed() {
    // localStorage.clear();
  },
  watch: {
    $route(to, from) {
      localStorage.removeItem("exportSortKey");
      localStorage.removeItem("exportSortValue");
    },
  },
};
</script>
<style lang="less">
.specialPagination {
  display: flex;
  align-items: center;
  .none {
    flex: 1;
  }
}
.tableNoPadding {
  .el-table__cell {
    padding: 1px !important;
  }
  .cell {
    line-height: 16px !important;
  }
}
.onlinePreviewIframe {
  width: 100%;
  height: calc(100vh - 400px);
}
.secondary {
  color: @secondColor !important;
}
.secondary.is-disabled {
  color: #999 !important;
}

.dangerBtn.is-disabled {
  color: #999 !important;
}
// 一行两个表单统一样式
/** 表单一行两个  start */
.elFormItemW50 {
  max-width: 1100px;
  .el-form {
    display: flex;
    flex-wrap: wrap;
  }
  .el-form-item:nth-child(2n) {
    margin-right: 0 !important;
  }
  .el-form-item {
    width: 49%;
    display: flex !important;
    margin-right: 2% !important;
    .el-form-item__content {
      flex: 1;
      margin-left: 0 !important;
      .el-date-editor.el-input {
        width: 100%;
      }
    }
  }
}
/** 表单一行两个  end */
.operationBtn {
  max-width: 1100px;
  text-align: center;
  margin-top: 20px;
}
// 初始化样式
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.el-card {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none;
}
/* 单页面顶部查询条件统一样式  start*/
.spaceBetween {
  justify-content: space-between;
}
.el-cascader {
  .el-input {
    width: 95% !important;
  }
  input {
    width: 100% !important;
  }
}
.el-cascader.width100 {
  .el-input {
    width: 100% !important;
  }
}
.el-card__header {
  padding: 10px 20px 20px !important;
}
.query,
.spaceBetween {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .el-select,
  .el-input,
  .el-picker,
  .el-date-editor,
  .el-radio-group {
    margin-right: 10px;
    width: 150px;
  }
  .el-button,
  .el-select,
  .el-input,
  .el-picker,
  .el-date-editor,
  .el-radio-group {
    margin-top: 10px;
  }
  .el-select {
    .el-input {
      margin-left: 0;
      margin-top: 0;
      width: 100%;
    }
  }
}
.el-range-separator {
  width: 10% !important;
}
/* 单页面顶部查询条件统一样式  end*/

/* 上传图片通用样式修改 start*/
.width50height50 {
  .avatar-uploader-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
.width200height200 {
  .avatar-uploader-icon {
    font-size: 30px;
    width: 200px;
    height: 200px;
    line-height: 200px;
  }
  img {
    max-width: 100%;
    max-height: 200px;
  }
}
.avatar-uploader-icon {
  color: #8c939d;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
/* tabel 样式定制化 start */
// .el-table {
//   .el-table__header-wrapper,
//   .el-table__fixed-header-wrapper {
//     .el-table__cell {
//       // padding: 6px 0;
//     }
//   }
//   .el-table__body-wrapper,
//   .el-table__fixed-body-wrapper {
//     .el-table__cell {
//       // padding: 0;
//     }
//   }
// }
/* tabel 样式定制化 end */

/* 上传图片通用样式修改 end*/

// 表单中的备注
.formPs {
  color: #aaa;
}

//表格中的操作栏统一样式
.tableOperation {
  .operationLine {
    color: #f0efee;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.dangerBtn {
  color: @dangerColor !important;
}
.el-dropdown-menu {
  .el-dropdown-menu__item {
    .el-button {
      width: 100%;
      text-align: center;
    }
  }
}
#app {
  color: #333;
  .appMain {
    display: flex;
    .appRight {
      flex: 1;
    }
    .version {
      width: 100vw;
      .routerView {
        height: ~"calc(100vh - 60px - 50px )";
      }
    }
    .routerView {
      height: ~"calc(100vh - 60px - 50px)"; // 减去header  减去tagNavigation
      // width: ~"calc(100vw - 200px)";
      overflow-y: auto;
      position: relative;
      padding: 10px;
      background-color: #eee;
      > .el-card {
        background-color: #fff;
      }
      .routerBack {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 1;
      }
      //表格中底部的分页统一样式
      .floatRight {
        float: right;
        margin: 10px 0px;
      }
      // 标题加表单的样式
      .info {
        border: 1px solid #eee;
        padding-top: 30px;
        position: relative;
        margin-top: 40px;
        .title {
          position: absolute;
          top: -10px;
          left: 30px;
          background-color: #fff;
          padding-left: 20px;
          padding-right: 20px;
          font-size: 14px;
          color: #ddd;
        }
      }
      // 操作日志页面共同样式
      .journalPage {
        .el-table {
          margin-top: 20px;
          .keyValue {
            .key {
              color: #999;
            }
          }
        }
      }
    }
  }
}
</style>
