import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入并注册插件
import plugins from './plugins'
Vue.use(plugins)
// 引入less中的全局变量
import '@/assets/css/public.less'

/* 引入 ElementUI*/
import ElementUI from "element-ui"
// import "element-ui/lib/theme-chalk/index.css";
import '@/assets/theme/index.css'
Vue.use(ElementUI)
// 引入echart图表
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 引入导出excel插件
import FileSaver from "file-saver"
import XLSX from "xlsx"
Vue.prototype.$fileSaver = FileSaver
Vue.prototype.$xlsx = XLSX
// 引入axios 并配置axios和qs
import axios from "axios"
Vue.prototype.$http = axios
import qs from "qs"
Vue.prototype.$qs = qs
/* 配置接口基础api地址 */
if (location.port || location.host.indexOf("test.") != -1) {
  axios.defaults.baseURL = "http://192.168.1.154:6062/"
  axios.defaults.baseURL = "http://s8434004c4.yicp.fun:80/"
  // axios.defaults.baseURL = "http://s8434004c4.yicp.fun:16430/"
  axios.defaults.baseURL = "https://apilims.ardentbiomed.com.cn/"
} else
  axios.defaults.baseURL = "https://apilims.ardentbiomed.com.cn/"
// 请求拦截器
axios.interceptors.request.use(
  config => {
    let flag = true
    if (localStorage.getItem("access-token")) {
      let roleName = ''
      if (localStorage.getItem('loginInfo')) {
        let loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
        if (
          loginInfo.sysRoleList &&
          loginInfo.sysRoleList.length > 0 &&
          loginInfo.sysRoleList[0].roleName) {
          roleName = loginInfo.sysRoleList[0].roleName
        } else roleName = loginInfo.roleName
      }
      config.headers['access-token'] = localStorage.getItem('access-token')
      if (roleName !== '超级管理员') flag = false
    }
    config.headers['isSkip'] = flag
    if (config.qs == "yes")
      config.data = qs.stringify(config.data)
    return config
  },
  err => {
    // 对请求错误做些什么
    return Promise.reject(err)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  res => {
    // 对响应数据做些什么
    if (res.data.code == 101 || res.data.code == 102 || res.data.code == 103 || res.data.code == 104 || res.data.code == 105) {
      Vue.prototype.$logout()
      return
    }
    if (res.data.code == 500) {
      Vue.prototype.$VueMessage({
        type: "warning",
        message: '操作失败，请联系管理员'
      })
    } else if (res.data.code == 400 && res.data.msg == 'errorModel') {

    } else if (res.data.code == 400) {
      Vue.prototype.$VueMessage({
        type: "warning",
        message: res.data.msg || '操作失败，请联系管理员'
      })
    }
    return res
  },
  err => {
    console.log(err)
    Vue.prototype.$VueMessage({
      type: "warning",
      message: '操作失败，请联系管理员'
    })
    return err.response
  }
)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
