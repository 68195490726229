<template>
  <div class="currencyHeader">
    <img
      src="@/assets/images/logo/logo.png"
      class="logo"
      :style="{ opacity: $store.state.hide ? '0' : '1' }"
    />
    <div class="menu">
      <div
        :class="{
          item: true,
          active: item.menuId == $store.state.activeMenuId,
        }"
        v-for="(item, index) in $store.state.menuList"
        :key="index"
      >
        <div
          class="name"
          @click="goPage(item.url, item.menuId, item.list, index)"
        >
          {{ item.name }}
        </div>
        <div class="submenu oneLevel" v-if="item.list.length > 0">
          <div
            class="submenuItem"
            v-for="(v, i) in item.list"
            :key="i"
            @click="goPage(v.url, item.menuId, item.list, index)"
          >
            <div class="submenuItemName">
              {{ v.name }}
            </div>

            <!-- <div class="submenu twoLevel" v-if="v.list.length > 0">
              <div
                class="submenuItem"
                v-for="(v2, i2) in v.list"
                :key="i2"
                @click="goPage(v2.url, item.menuId)"
              >
                <div class="submenuItemName">{{ v2.name }}</div>
                <div class="submenu threeLevel" v-if="v2.list.length > 0">
                  <div
                    class="submenuItem"
                    v-for="(v3, i3) in v2.list"
                    :key="i3"
                    @click="goPage(v3.url, item.menuId)"
                  >
                    <div class="submenuItemName">{{ v3.name }}</div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="none"></div>
    <el-dropdown trigger="click" @command="handleCommand">
      <div class="userInfo">
        <div class="headImgBox">
          <img
            src="@/assets/images/header/defaultHeadPortrait.png"
            class="headImg"
          />
        </div>
        <div class="center">
          <div class="name">{{ loginInfo.name }}</div>
          <div class="position">{{ loginInfo.roleName }}</div>
        </div>
        <img src="@/assets/images/header/arrowBottomIcon.png" class="arrow" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="editPassWord">修改密码</el-dropdown-item>
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 修改密码弹窗 -->
    <CurrencyEditPassword ref="CurrencyEditPassword"></CurrencyEditPassword>
  </div>
</template>
  <script>
import CurrencyEditPassword from "./currencyEditPassword.vue";
export default {
  components: { CurrencyEditPassword },
  data() {
    return {
      loginInfo: {},
    };
  },
  mounted() {
    if (localStorage.getItem("loginInfo")) {
      let data = JSON.parse(localStorage.getItem("loginInfo"));
      if (data.sysRoleList && data.sysRoleList.length > 0)
        data.roleName = data.sysRoleList[0].roleName;
      this.loginInfo = data;
    }
  },
  methods: {
    toggleVersion() {
      this.$store.state.version = !this.$store.state.version;
      localStorage.setItem("version", !this.$store.state.version);
    },
    goPage(url, menuId, list, index) {
      if (url && url != this.$route.name) this.$router.replace({ name: url });
      else {
        if (list) {
          if (list.length > 0 && this.$route.name != list[0].url)
            this.$router.replace({ name: list[0].url });
        }
      }
      localStorage.setItem("activeMenuIndex", index);
      this.$store.state.activeMenuIndex = index;
      this.$store.state.secondLevelMenuList = list;
      if (menuId) this.$store.state.activeMenuId = menuId;
    },

    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "logout")
        this.$confirm("确定退出登录吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$logout();
          })
          .catch(() => {
            this.$VueMessage({
              message: "取消退出登录操作",
            });
          });
      else if (command == "editPassWord")
        this.$refs.CurrencyEditPassword.init();
    },
  },
};
</script>
  <style lang="less" scoped>
.currencyHeader {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 16px;
  background-color: @secondBackgroundColor;
  color: #fff;
  width: 100%;
  .logo {
    height: 40px;
    margin-left: 20px;
    margin-right: 0px;
  }
  .none {
    flex: 1;
  }
  .menu {
    // width: ~"calc(100vw - 780px)";
    width: ~"calc(100vw - 480px)";
    white-space: nowrap;
    display: flex;
    height: 60px;
    .item:hover {
      .oneLevel {
        display: block;
      }
    }
    .item {
      position: relative;
      .name {
        padding-left: 10px;
        padding-right: 10px;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
      }
      .submenuItem {
        position: relative;
      }
      .twoLevel,
      .oneLevel,
      .threeLevel {
        display: none;
      }
      .oneLevel {
        top: 60px;
        left: 0;
      }
      .twoLevel {
        top: 0;
        left: 150px;
      }
      .threeLevel {
        top: 0;
        left: 150px;
      }
      .oneLevel {
        .submenuItem:hover {
          .twoLevel {
            display: block;
          }
        }
      }
      .twoLevel {
        .submenuItem:hover {
          .threeLevel {
            display: block;
          }
        }
      }
      .submenu {
        position: absolute;
        background-color: @secondBackgroundColor;
        cursor: pointer;
        text-align: center;
        z-index: 999;
        width: 150px;
        .submenuItem {
          padding: 10px 6px;
          font-size: 14px;
        }

        .submenuItem:hover,
        .submenuItem.active {
          background-color: @mainBackgroundColor;
          color: #fff;
        }
      }
    }
    .item:hover,
    .item.active {
      background-color: @mainBackgroundColor;
      color: #fff;
    }
  }
  .otherOperation {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    .item {
      display: flex;
      align-items: center;
      padding-left: 30px;
      padding-right: 10px;
      position: relative;
      cursor: pointer;
      .icon {
        width: 24x;
        margin-right: 10px;
      }
    }
  }
  .userInfo {
    display: flex;
    align-items: center;
    padding-right: 20px;
    margin-left: 60px;
    cursor: pointer;
    color: #fff;
    .headImgBox {
      width: 36px;
      height: 36px;
      border-radius: 40px;
      background-color: #fff;
      margin-right: 10px;
    }
    .headImg {
      width: 26px;
      margin-top: 3px;
      margin-left: 5px;
    }
    .center {
      .position {
        font-size: 12px;
      }
    }
    .arrow {
      width: 18px;
      margin-left: 10px;
    }
  }
}
</style>
  