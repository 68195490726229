<template>
  <div class="currencyLogo">
    <img src="@/assets/images/logo/logo.png" class="logo" />
    <!-- @click="toggleVersion" -->
    <div class="name">实验室信息管理系统</div>
  </div>
</template>
<script>
export default {
  methods: {
    toggleVersion() {
      this.$store.state.version = !this.$store.state.version;
      localStorage.setItem("version", !this.$store.state.version);
    },
  },
};
</script>
<style lang="less" scoped>
.currencyLogo {
  text-align: center;
  color: #fff;
  height: 100px;
  .logo {
    height: 50px;
    display: block;
    margin: 20px auto;
    // opacity: 0;
  }
}
</style>
