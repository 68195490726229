<template>
  <div class="currencySidebar" v-if="$store.state.currencySidebarFlag">
    <currencyLogoVue />
    <currencyMenuVue />
  </div>
</template>
<script>
import currencyLogoVue from "./currencyLogo.vue";
import currencyMenuVue from "./currencyMenu.vue";
export default {
  components: {
    currencyLogoVue,
    currencyMenuVue,
  },
};
</script>
<style lang="less" scoped>
.currencySidebar {
  background-color: @secondBackgroundColor;
  width: 200px;
  height: 100vh;
}
</style>
