<template>
  <div class="currencyHeader">
    <!-- <div class="currencySidebarFold" @click="setCurrencySidebarFlag">
      <i class="el-icon-arrow-left" v-if="$store.state.currencySidebarFlag"></i>
      <i class="el-icon-arrow-right" v-else></i>
    </div> -->
    <div class="menu">
      <div
        :class="{
          item: true,
          active: item.menuId == $store.state.activeMenuId,
        }"
        v-for="(item, index) in $store.state.menuList"
        :key="index"
        :id="item.menuId"
        @click="toggleMenu(index)"
      >
        <img :src="item.icon" v-if="item.icon" class="icon" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div class="none"></div>
    <!-- <div class="otherOperation">
      <el-badge :value="200" :max="99" class="item">
        <img src="@/assets/images/tagNavigation/moreIcon.png" class="icon" />
        <div class="name">预警</div>
      </el-badge>
      <div class="item">
        <img src="@/assets/images/tagNavigation/moreIcon.png" class="icon" />
        <div class="name">查询</div>
      </div>
      <el-badge :value="200" :max="99" class="item">
        <img src="@/assets/images/tagNavigation/moreIcon.png" class="icon" />
        <div class="name">公告</div>
      </el-badge>
    </div> -->
    <!-- 用户名下拉菜单 -->
    <el-dropdown trigger="click" @command="handleCommand">
      <div class="userInfo">
        <div class="headImgBox">
          <img
            src="@/assets/images/header/defaultHeadPortrait.png"
            class="headImg"
          />
        </div>
        <div class="center">
          <div class="name">{{ loginInfo.name }}</div>
          <div class="position">{{ loginInfo.roleName }}</div>
        </div>
        <img src="@/assets/images/header/arrowBottomIcon.png" class="arrow" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="editPassWord">修改密码</el-dropdown-item>
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 修改密码弹窗 -->
    <CurrencyEditPassword ref="CurrencyEditPassword"></CurrencyEditPassword>
  </div>
</template>
<script>
import CurrencyEditPassword from "./currencyEditPassword.vue";
export default {
  components: { CurrencyEditPassword },
  data() {
    return {
      loginInfo: {},
    };
  },
  mounted() {
    if (localStorage.getItem("loginInfo")) {
      let data = JSON.parse(localStorage.getItem("loginInfo"));
      if (data.sysRoleList && data.sysRoleList.length > 0)
        data.roleName = data.sysRoleList[0].roleName;
      this.loginInfo = data;
    }
  },
  methods: {
    setCurrencySidebarFlag() {
      this.$store.state.currencySidebarFlag =
        !this.$store.state.currencySidebarFlag;
      localStorage.setItem(
        "currencySidebarFlag",
        this.$store.state.currencySidebarFlag
      );
    },
    /**
     * 根据下标显示当前选中的菜单的子菜单，并且跳转到子菜单中的第一个菜单
     */
    toggleMenu(index) {
      let data = this.$store.state.menuList;
      let menuId = data[index].menuId;
      if (menuId != this.$store.state.activeMenuId) {
        localStorage.setItem("activeMenuIndex", index);
        localStorage.setItem("activeMenuId", data[index].menuId);
        this.$store.state.activeMenuId = data[index].menuId;
        this.$store.state.secondLevelMenuList = data[index].list;
        if (
          data[index].list.length > 0 &&
          this.$route.name != data[index].list[0].url
        )
          this.$router.replace({ name: data[index].list[0].url });
      }
    },

    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "logout")
        this.$confirm("确定退出登录吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$logout();
          })
          .catch(() => {
            this.$VueMessage({
              message: "取消退出登录操作",
            });
          });
      else if (command == "editPassWord")
        this.$refs.CurrencyEditPassword.init();
    },
  },
};
</script>
<style lang="less" scoped>
.currencyHeader {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 16px;
  background-color: @secondBackgroundColor;
  color: #fff;
  .currencySidebarFold {
    width: 40px;
    height: 60px;
    position: relative;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  .none {
    flex: 1;
  }
  .menu {
    overflow-x: auto;
    // width: ~"calc(100vw - 780px)";
    width: ~"calc(100vw - 480px)";
    white-space: nowrap;
    display: flex;
    height: 60px;
    .item {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      .icon {
        width: 24px;
        margin-right: 10px;
      }
    }
    .item:hover,
    .item.active {
      background-color: @mainBackgroundColor;
      color: #fff;
    }
  }
  .otherOperation {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    .item {
      display: flex;
      align-items: center;
      padding-left: 30px;
      padding-right: 10px;
      position: relative;
      cursor: pointer;
      .icon {
        width: 24x;
        margin-right: 10px;
      }
    }
  }
  .userInfo {
    display: flex;
    align-items: center;
    padding-right: 20px;
    margin-left: 60px;
    cursor: pointer;
    color: #fff;
    .headImgBox {
      width: 36px;
      height: 36px;
      border-radius: 40px;
      background-color: #fff;
      margin-right: 10px;
    }
    .headImg {
      width: 26px;
      margin-top: 3px;
      margin-left: 5px;
    }
    .center {
      .position {
        font-size: 12px;
      }
    }
    .arrow {
      width: 18px;
      margin-left: 10px;
    }
  }
}
</style>
