<template>
  <div class="currencyMenu">
    <div
      :class="{ item: true, active: $route.name && $route.name.indexOf(item.url) != -1 }"
      v-for="(item, index) in $store.state.secondLevelMenuList"
      :key="index"
      @click="goPage(item.url)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goPage(url) {
      if (url != this.$route.name) this.$router.replace({ name: url });
    },
  },
};
</script>
<style lang="less" scoped>
.currencyMenu {
  background-color: @secondBackgroundColor;
  font-size: 16px;
  line-height: 40px;
  height: ~"calc(100vh - 120px)";
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 10px;
  .item {
    cursor: pointer;
    padding-left: 30px;
    color: #fff;
    font-size: 14px;
  }
  .item.active,
  .item:hover {
    background-color: @mainBackgroundColor;
  }
}
</style>
